import styled from 'styled-components';
import background from '../images/background-blue.png';

export const Main = styled.div`
  padding:  ${props => props.$isMobileView ? '10px' : '30px 5px'};
  display: flex;
  min-height: ${props => props.$isMobileView ? `calc(100vh - ${20 + (props.$hasNavHeader ? 40 : 0)}px)` : `calc(100vh - ${60 + (props.$hasNavHeader ? 40 : 0)}px)`};
  flex-direction: column;
  align-items: center;
  background:  ${props => props.$backgroundColor ? props.$backgroundColor : `url(${background}) top left/cover no-repeat fixed`};
  /* 
    Above same as these
    background-image: url(${background});
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed; 
  */
`;

export const MainContent = styled.div`
  // background: #ffffff;
  width:  ${props => props.$isMobileView ? '100%' : '80%'};
  max-width: 1000px;
  min-width: ${props => props.$isMobileView ? 'auto' : '450px'};
  border-radius: 5px;
`;

export const Section = styled.div.attrs(() => ({
    className: 'section',
  }))`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
`;

export const SectionLabelText = styled.div`
  font-weight: bold;
  font-size: 20px;
  // color: #44ACE1;
  color: #2c2c2c;
  text-align: center;
`;

export const SectionLabel = (props) => {
  const styleProps = props.style || {};
  return (
    <div style={{ ...styleProps, display: 'flex' }} >
      <div style={{ background: '#ff9839', marginRight: '10px', width: '4px' }}>
        &nbsp;
      </div>
      <SectionLabelText style={{ padding: '8px 0px'}}>
        {props.children}
      </SectionLabelText>
    </div>
  )
}

export const Button = styled.div`
  display: flex;
  background: ${props => props.$disabled ? '#e4e4e4' : props.$colored ? '#0280c6' : '#ffffff'};
  color: ${props => props.$disabled ? '#555555' : props.$colored ? '#ffffff' : '#080808'};
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  cursor: ${props => props.$disabled ? 'default' : 'pointer' };
  &:hover {
    box-shadow: ${props => props.$disabled ? 'none' : '1px 1px 3px 1px rgba(68, 172, 225, 0.7)' };
  }
  &:active {
    background: ${props => props.$disabled ? '#e4e4e4' : 'rgba(68, 172, 225, 0.1)' };
  }
`;

export const TabButton = styled.div`
  display: flex;
  background: ${props => props.$selected ? '#0280c6' : '#ffffff'};
  color: ${props => props.$selected ? '#ffffff' : '#565656'};
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 3px 1px rgba(68, 172, 225, 0.7)
  }
  &:active {
    background: rgba(68, 172, 225, 0.1)
  }
`;

export const IconButtonWrapper = styled.div`
  display: inline-flex;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  color: ${props => props.$isDark ? '#ffffff' : '#6e6e6e'};
  &:hover {
    background: ${props => props.$isDark ? '#585858' : '#e9e9e9'};
  }
  &:active {
    background: ${props => props.$isDark ? '#858585' : '#b7b7b7'};
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  padding-left: 7px;

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;

    &:before {
      content: '⬢';
      color: #16B79E;
      font-size: 24px;
      position: absolute;
      left: -10px;
      top: 8px;
      /*top: 50%;*/
      transform: translateY(-50%);
    }

    /*
    &::marker {
      content: '•';
      color: #16B79E; 
      font-size: 64px;
      position: absolute;
    } */
  }
`;