import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalData } from '../GlobalData';

const Input = styled.input`
  flex: 1;
  background: ${props => props.disabled ? '#f9f9f9' : '#ffffff'};
  border: ${props => props.$isInvalid ? '1px solid #ef0c0c' : '1px solid #c4c4c4'};
  border-radius: 5px;
  padding: 10px 20px 10px 30px;
  width: ${props => props.$width};
  box-sizing: border-box;
  text-align: left;
  display: flex;
  align-items: center;
  color: #080808;
  font-size: 14px;
  outline: 0;
  &:focus {
    border: 1px solid rgba(68, 172, 225, 0.7);
    box-shadow: 0px 0px 3px 2px rgba(68, 172, 225, 0.7);
  }
`;

export default ({
  value: defaultValue,
  onChange,
  width: widthProp,
  disabled,
  isInvalid,
  options = {}
}) => {
  const {
    autoCommas
  } = options;

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const width = isMobileView ? '100%' : (widthProp ? widthProp : 'auto');

  const [value, setValue] = useState(defaultValue || '')

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const onInputChange = (e) => {
    const rawVal = e.target.value;
    const val = rawVal.replaceAll(',', '');

    if (val === '') {
      setValue(val)
      return;
    }

    const regex = /^\d*\.?\d*$/;

    const changeAllowed = val.match(regex);

    if (changeAllowed) {

      let formattedVal = val;

      // Format val before change
      if (val.indexOf('.') > -1) {
        const parts = val.split('.');
        const numbers = parts[0] || '';
        const decimals = (parts[1] || '').substr(0,2);
        formattedVal = `${autoCommas ? Number(numbers).toLocaleString() : numbers}.${decimals}`
      } else if (autoCommas) {
        formattedVal = Number(val).toLocaleString()
      }

      setValue(formattedVal)
    }
  }

  return (
    <div style={{
      display: 'flex',
      flex: 1,
      position: 'relative'
    }}>
      {
        Boolean(value) &&
        <div style={{
          position: 'absolute',
          top: '11px',
          left: '20px',
          color: '#6a6a6a'
        }}>
          {'$'}
        </div>
      }
      <Input
        type='text'
        $width={width}
        $isInvalid={isInvalid}
        value={value}
        onChange={onInputChange}
        disabled={disabled}
        data-1p-ignore
      >
      </Input>
    </div>
  )
}