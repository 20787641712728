import React from 'react';
import { ImSpinner2 as LoadingIcon } from 'react-icons/im';

// No need for below import as long as we always load animations at the top application level
// import './animations.css';

export default ({
  speed: speedRaw = '1.5'
}) => {
  const speed = `${speedRaw}s`
  return (
    <LoadingIcon style={{ 
      color: '#6e6e6e', 
      fontSize: '32px',
      animation: `rotate ${speed} linear infinite`
    }} />
  )
}