export default () => {
    // const host = window.location.host;
    // const domain = 'prosolve.com';
    // const subDomain = 'go';
    // const apiSubDomain = 'api';

    let apiBaseUrl;
    // if (host.indexOf('localhost') > -1) {
    //     apiBaseUrl = `http://localhost:3003/api`;
    // } else {
    //     // detect env
    //     // const parts = host.split('.');
    //     // Look backward to always get part prior to 'prosolve.com'
    //     // const envPart = parts[parts.length-3];
    //     // const env = envPart !== subDomain ? `.${envPart}` : '';
    //     const env = '.qa'
    //     apiBaseUrl = `https://${apiSubDomain}.${subDomain}${env}.${domain}/api`
    // }

    // For this code always just use the production version of the go subdomain
    apiBaseUrl = `https://api.go.prosolve.com/api`

    // apiBaseUrl = `http://localhost:3003/api`;
    
    return async (url, ...args) => {
        return await fetch(`${apiBaseUrl}/${url}`, ...args)
    }
};