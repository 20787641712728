import React, { useState } from "react";
import useForm from './hooks/useForm';
import FormField from "./CommonComponents/FormField";
import { Button } from './CommonComponents/BaseComponents'
import LoadingIcon from "./CommonComponents/Animations/LoadingIcon";
import useFetch from './hooks/useFetch';
// import background from './images/background-blue.png';
import useMediaQuery from './hooks/useMediaQuery';

export default () => {
  const isSmallView = useMediaQuery('(max-width: 620px)');

  const fetch = useFetch();

  const [isBusy, setIsBusy] = useState(false);
  const [showInvalidLogin, setShowInvalidLogin] = useState(false);

  const formSchema = {
    requiredFields: ['code'],
    initData: {
      code: ''
    }
  }

  const formData = useForm(formSchema)

  const onClickSubmit = async () => {
    if (formData.isValid() && !isBusy) {
      setIsBusy(true)

      const { code: codeRaw = '' } = formData.values;

      const code = codeRaw.toUpperCase();

      const response = await fetch(
        `quest/code/redeemed?code=${code}`, 
        { 
          method: 'GET',
        }
      )

      const jsonData = await response.json() || {};

      const { 
        success,
        code: questCode = '',
        // classId = '',
        seasonIndex = '',
        episodeIndex = '',
        // classLevel,
      } = jsonData;

      if (success && questCode && seasonIndex && seasonIndex === 201) {
        setShowInvalidLogin(false)
        // const primaryValue = classLevel?.toLowerCase().includes("primary") ? '1' : '0';
        // const seasonLink = `https://playquestboxes.com/game?classid=${classId}&season=${seasonIndex}&student=1`;

        const seasonLink = `https://playquestboxes.com/starforce/e${episodeIndex || 0}activity`;
        window.open(seasonLink,'_self');
      } else {
        setShowInvalidLogin(true)
      }

      setIsBusy(false)
    }
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSubmit();
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: isSmallView ? 'column' : 'row',
      rowGap: '15px',
      // padding: '20px',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto',
      overflowX: 'hidden',
      // background: '#ffffff'
      // background: `url(${background})  top left/cover no-repeat fixed`
      background: `url("https://api.portal.questboxes.com/api/file/Product%20Images/starforce-splash.png") top left/cover no-repeat fixed`
    }}>

      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: isSmallView ? 'flex-start' : 'center',
        alignItems: 'center',
        padding: '20px',
        zIndex: '100',
        marginTop: isSmallView ? '-15px' : '0px'
      }}>

       <div style={{ display: 'flex', flexDirection: 'column' }}>

          <div style={{ flex: 1, display: 'flex', marginBottom: '20px', textAlign: isSmallView ? 'center' : 'center' }}>
            <div style={{ color: '#ffffff'}}>
              <div style={{ fontWeight: 'bold', fontSize: '40px', marginBottom: '0px', color: '#ffc200' }}>{"ENTER CLASS CODE"}</div>
              {/* <div style={{ fontSize: '26px', maxWidth: isSmallView ? 'none' : '600px'}}>
                {"Please enter your provided Quest Code to gain access!"}
              </div> */}
            </div>
          </div>

          <FormField
            fieldStyle={{ 
              fontSize: '18px', 
              textAlign: isSmallView ? 'center' : 'left',
              textTransform: 'uppercase'
            }}
            // label='Code'
            // labelStyle={{ color: '#ffffff', fontWeight: 'bold'}}
            fieldId='code'
            type='text'
            // placeholder='Email'
            formData={formData}
            onKeyDown={onKeyDown}
          />

          <div style={{ height: '25px', position: 'relative'}}>

          {
            (!isBusy && showInvalidLogin) ?
            <div style={{
              color: '#ef0c0c',
              fontStyle: 'italic',
              fontSize: '14px',
              position: 'absolute',
              top: '3px',
              left: '3px'
            }}>
              {'Code not found or has not been redeemed by your teacher.'}
            </div>
            :
            <div>&nbsp;</div>
          }

          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isSmallView ? 'center' : 'center' }}>
            <Button 
              style={{ 
                display: 'inline-flex', 
                marginRight: '10px', 
                fontWeight: 'bold', 
                fontSize: '24px',
                width: '150px', 
                height: '40px',
                background: 'transparent'
              }} 
              onClick={onClickSubmit} 
              $colored={true}
            >
                {'SUBMIT'}
            </Button>
            {
              isBusy &&
              <LoadingIcon />
            }
          </div>

        </div>
      </div>
    </div>
  )
}